


















import { identifyUser } from "@/services/analytics";
import stripe from "@/services/stripe";
import store from "@/store";
import { loadDynamicModules } from "@/store/dynamicModules";
import { useModule } from "@/store/hooks";
import authModule from "@/store/modules/auth";
import { uiModule } from "@/store/modules/ui";
import { configureScope } from "@sentry/vue";
import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { useRouter } from "../router";
import { RoutesConfig } from "../router/Routes";
import { userModule } from "../store/modules/user";

export default defineComponent({
  name: "GodMode",
  setup() {
    const { currentRoute, navigate } = useRouter();
    const { mutations } = useModule(authModule);
    const vm = reactive({
      key: "ll123",
      pass: "",
    });
    onMounted(() => {
      if (!userModule.state.user.employee) {
        userModule.state.user.seller ? navigate(RoutesConfig.SELLER_DASHBOARD.path) : navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      }
      if (window.location.hostname !== "localhost") window.Intercom("shutdown");
    });
    const enabled = computed(() => vm.key === vm.pass);
    const uid = computed(() => currentRoute?.value?.params?.uid);
    async function startGodMode() {
      if (enabled.value) {
        identifyUser(uid.value);
        configureScope((scope) => {
          scope.setUser({
            id: uid.value,
          });
        });
        await Promise.all([loadDynamicModules(store, uid.value), stripe.setup()])
          .then(() => {
            mutations.authorize(uid.value);
            mutations.simulate(uid.value);
            if (userModule.state.user.seller) uiModule.actions.fetchIncompleteReportsCount();
            else {
              uiModule.actions.fetchTeamCount();
              uiModule.actions.fetchProjectCount();
            }
            return uiModule.actions.fetchUnreadNewsMessageCount();
          })
          .catch(() => {
            throw new Error(
              "Failed to load dynamic modules despite being authorized",
            );
          });
        userModule.state.user.seller ? navigate(RoutesConfig.SELLER_DASHBOARD.path) : navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      }
    }
    return {
      currentRoute,
      enabled,
      startGodMode,
      uid,
      vm,
    };
  },
});
